@tailwind base;
@tailwind components;
@tailwind utilities;


/* // <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 300 to 900 */

body {
  font-family: "Rubik", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.information {
    background-image: linear-gradient(to right, #004985, #4E81AA);
}

.join {
    background-color: rgba(240, 242, 244, 0.9);
}

.whishlist {
    background-image: linear-gradient(to right, rgba(240, 242, 244, 1), rgba(240, 242, 244, 0.8));
}

.sliderImg {
    transition-delay: 0.5s;
}

.service_text {
    color: transparent;
    -webkit-text-stroke: 1px rgba(210, 212, 213, 1);
}

.react-responsive-modal-closeButton{
    display: none !important;
}
.react-responsive-modal-modal{
    border-radius: 24px !important;
}

.custom-item-class {
    padding: 0 !important; /* Remove padding */
}


@media only screen and (min-width: 430px){
    .mobile_hero{
        margin-left: 18px;
    }
}

.privacy .iub_footer p:nth-child(3) {
    display: none !important;
  }
  .privacy_pl .iub_footer p:nth-child(2) {
    display: none !important;
  }
  
  .cookies .iub_footer p:nth-child(3) {
    display: none !important;
  }
  
  .cookies .iub_footer p:nth-child(2) {
    display: none !important;
  }
  
  .terms .iub_footer p:nth-child(2) {
    display: none !important;
  }

  @media (min-width: 1024px) and (max-width: 1366px) {
    .small_phone{
      display: none;
      border: 1pt solid red;
    }
  }